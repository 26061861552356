import * as R from 'ramda';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { withMenu } from '../../../reactutils/withMenu';
import { Section, SectionHeading } from '../../common/Section';
import { MenuBetting } from '../../MenuBetting';
import { systemPrivacyStatementSelector } from '../../System/selectors';
import { withFooter } from '../../../reactutils/withFooter';

const PrivacyStatementComponent: FC<{}> = () => {
    const privacyStatement = useSelector(systemPrivacyStatementSelector);
    return (
        <>
            <Section>
                <SectionHeading>AGB und Datenschutz</SectionHeading>
                <ReactMarkdown>{privacyStatement || ''}</ReactMarkdown>
            </Section>
        </>
    );
};

export const PrivacyStatement = R.compose(withMenu(<MenuBetting />), withFooter())(PrivacyStatementComponent);
