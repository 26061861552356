import * as R from 'ramda';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { withFooter } from '../../../reactutils/withFooter';
import { withMenu } from '../../../reactutils/withMenu';
import { Section, SectionHeading } from '../../common/Section';
import { MenuBetting } from '../../MenuBetting';
import { systemImprintStatementSelector } from '../../System/selectors';

const ImprintStatementComponent: FC<{}> = () => {
    const statement = useSelector(systemImprintStatementSelector);
    return (
        <>
            <Section>
                <SectionHeading>Impressum</SectionHeading>
                <ReactMarkdown>{statement || ''}</ReactMarkdown>
            </Section>
        </>
    );
};

export const ImprintStatement = R.compose(
    withMenu(<MenuBetting />),
    withFooter()
)(ImprintStatementComponent);
